
@font-face {
    font-family: 'Optum Sans';
    src: url('./assets/fonts/optum-sans-regular.woff2') format('woff2'),
    url('./assets/fonts/optum-sans-regular.otf') format('opentype'),
    url('./assets/fonts/optum-sans-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('./assets/fonts/optum-sans-bold.woff2') format('woff2'),
    url('./assets/fonts/optum-sans-bold.otf') format('opentype'),
    url('./assets/fonts/optum-sans-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}